import React, { useState } from 'react';
import Sidebar from '../components/Sidebar/Sidebar';
import Navbar from '../components/Navbar/Navbar';
import Why from '../components/Why/Why';
import FAQSection from '../components/FaqSection';
import Footer from '../components/Footer/Footer';
import VrSection from '../components/Vr';
import Hero from '../components/Hero';
import LeaveRequest from '../components/LeaveRequest';
import Tabs from '../components/Tabs/Tabs';
import Map from '../components/Map';

import { SliderData } from '../SliderData';

const Home = () => {

const [isOpen, setOpen] = useState(false);

const toggle = () => {
    setOpen(!isOpen);
}

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <Hero slides={SliderData}/>
            <VrSection />
            <Tabs />
            <Why />
            <FAQSection />
            <LeaveRequest />
            <Map/>
            <Footer/>
        </>
    )
}

export default Home
