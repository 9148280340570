import React, { useState, useRef, useEffect } from 'react';
import styled, {css} from 'styled-components';
import { IoMdArrowRoundForward } from 'react-icons/io';
import { IoArrowForward, IoArrowBack } from 'react-icons/io5';
import { Link as LinkScroll } from 'react-scroll';

const HeroSection = styled.section`
    height: 100vh;
    max-height: 1100px;
    position: relative;
    overflow: hidden;
    top: -50px;
`;

const HeroWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
`;

const HeroSlide = styled.div`
    z-index: 1;
    width: 100%;
    height: 100%;
`;

const HeroSlider = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100vh;
        bottom: 0vh;
        left: 0;
        overflow: hidden;
        opacity: 0.4;
        background: linear-gradient( 0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.6) 100%);
    }
`;

const HeroImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    object-fit: cover;

    @media screen and (max-width: 786px) {
        object-fit: cover;
        height: 160vw;
    }

    @media screen and (max-width: 482px) {
        object-fit: cover;
        height: 200vw;
        }

    @media screen and (max-width: 406px) {
        object-fit: cover;
        height: 200vw;
    }


    @media screen and (max-width: 380px) {
        object-fit: cover;
        height: 240vw;
    }

    @media screen and (max-width: 326px) {
        object-fit: cover;
        height: 240vw;
    }
`;

const HeroContent = styled.div`
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    width: cal(100% - 100px);
    color: white;
    margin-left: 250px;

    h1{
        font-size: clamp(1rem, 8vw, 3rem);
        font-weight: 600;
        width: 50%;
        text-shadow: 0px 0px 20px rgba(0,0,0,0.4);
        text-align: left;
        margin-bottom: 1.2rem;

        @media screen and (max-width: 1150px) {
            font-size: 26px;
            margin-left: -190px;
            margin-top: 40px;
            width: 100%;    
        }

        @media screen and (max-width: 1070px) {
            font-size: 26px;
            margin-left: -160px;
            margin-top: 40px;
            width: 100%;
        }

        @media screen and (max-width: 786px) {
            font-size: 26px;
            margin-left: -80px;
            margin-top: 40px;
            width: 100%;
        }

        @media screen and (max-width: 650px) {
            font-size: 23px;
            margin-left: -190px;
            margin-top: 40px;
            width: 100%;
        }

        @media screen and (max-width: 486px) {
            font-size: 20px;
            margin-left: -220px;
            margin-top: 40px;
            width: 100%;
        }

        @media screen and (max-width: 376px) {
            font-size: 19px;
            margin-left: -220px;
            margin-top: 50px;
            width: 100%;
        }

        @media screen and (max-width: 350px) {
            font-size: 16px;
            margin-left: -220px;
            margin-top: 50px;
            width: 100%;
        }
    }

    p {
        margin-bottom: 1.8rem;
        width: 50%;
        font-size: clamp(0.4rem, 4vw, 1.2rem);
        text-shadow: 0px 0px 20px rgba(0,0,0,0.4);
        color: white;

        @media screen and (max-width: 1150px) {
            font-size: 19px;
            margin-left: -190px;
            width: 100%;    
        }

        @media screen and (max-width: 1070px) {
            margin-left: -160px;
            width: 100%;
            font-size: 18px;
        }

        @media screen and (max-width: 786px) {
            margin-left: -80px;
            width: 100%;
            font-size: 18px;
        }

        @media screen and (max-width: 650px) {
            margin-left: -190px;
            width: 100%;
            font-size: 18px;
        }

        @media screen and (max-width: 486px) {
            font-size: 15px;
            margin-left: -220px;
            width: 100%;
        }

        @media screen and (max-width: 376px) {
            font-size: 14px;
            margin-left: -220px;
            width: 100%;
        }

        @media screen and (max-width: 356px) {
            font-size: 12px;
            margin-left: -220px;
            width: 100%;
        }

        @media screen and (max-width: 326px) {
            line-height: 1.5em;
            height: 3em;
            overflow: hidden;
            white-space: nowrap;
        } 
    }
`;

const Arrow = styled(IoMdArrowRoundForward)`
    margin-left: 0.5rem;
`;

const ArrowButtons = css`
    width: 110%;
    height: 110%;
    color: white;
    cursor: pointer;
    background: #083884;
    border-radius: 50px;
    padding: 10px;
    margin-right: 1rem;
    user-select: none;
    transition: 0.3s;

    &:hover {
        background: pink;
        transform: scale(1.05)
    }
`;

const PrevArrow = styled(IoArrowBack)`
    ${ArrowButtons}
`;

const NextArrow = styled(IoArrowForward)`
    ${ArrowButtons}
`;

const SliderButtons = styled.div`
    position: absolute;
    bottom: 50px;
    right: 50px;
    display: flex;
    z-index: 10;

`;

const Btn = styled(LinkScroll)`
    background-color: #083884;
    padding: 12px 30px;
    border-radius: 50px;
    border: none;
    width: 20%;
    outline: none;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-weight: bold;
    cursor: pointer;
    svg {
        font-size: 1.1rem;
        color: white;
    }

        @media screen and (max-width: 1150px) {
            padding: 12px 30px;
            width: 40%;
            margin-left: -190px;    
        }

        @media screen and (max-width: 1070px) {
            padding: 12px 30px;
            width: 40%;
            margin-left: -160px;
        }

        @media screen and (max-width: 786px) {
            padding: 12px 30px;
            width: 60%;
            margin-left: -80px;
        }

        @media screen and (max-width: 650px) {
            padding: 12px 30px;
            width: 50%;
            margin-left: -190px;
        }

        @media screen and (max-width: 486px) {
            padding: 12px 30px;
            width: 80%;
            margin-left: -220px;
        }

        @media screen and (max-width: 396px) {
            padding: 20px;
            width: 100%;
            font-size: 10px;
            margin-left: -220px;
        }

        
`;

const Hero = ({ slides }) => {

    const [ current, setCurrent ] = useState(0)
    const lenght = slides.length
    const timeOut = useRef(null)

    useEffect(() => {
        const nextSlide = () => {
            setCurrent(current => ( current === lenght - 1 ? 0 : current + 1 ));
        };

        timeOut.current = setTimeout(nextSlide, 4000);

        return function() {
            if (timeOut.current) {
                clearTimeout(timeOut.current);
            }
        }

    }, [current, lenght])
    
    const nextSlide = () => {
        setCurrent(current === lenght - 1 ? 0 : current + 1)
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? lenght - 1 : current -1 )
    }

    if(!Array.isArray(slides) || slides.length <= 0) {
        return null;
    }

    return (
    <HeroSection>
        <HeroWrapper>
            {slides.map((slide, index) => {
                return (
                    <HeroSlide key={index}>
                        {index === current && (
                            <HeroSlider>
                                <HeroImage src={slide.image} alt={slide.alt}/>
                                    <HeroContent>
                                        <h1>{slide.title}</h1>
                                        <p>{slide.description}</p>
                                        <Btn to='request'>
                                            {slide.label}
                                        </Btn>
                                    </HeroContent>
                            </HeroSlider> 
                        )}
                    </HeroSlide>
                )
            })}
            <SliderButtons>
                <PrevArrow onClick={prevSlide}/>
                <NextArrow onClick={nextSlide}/>
            </SliderButtons>
        </HeroWrapper>
    </HeroSection>
    );
};

export default Hero;
