import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import emailjs from '@emailjs/browser';


const LeaveRequest = () => {

    const form= useRef();

    const [ name, setName ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ email, setEmail ] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_44v03na', 'template_az0ycpp', form.current, 'user_e5bcf377RO7fSwbqGr7iL')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          alert("Email sent")
          e.taget.reset();
          
      };

    const handleName = (e) => {
        setName(e.target.value);
    }

    const handlePhone = (e) => {
        setPhone(e.target.value);
    }

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    return (
        <Section id="request">
            <TopHeading>Leave a Request</TopHeading>
            <SmallHeading>Leave a request so that our specialists can contact you</SmallHeading>
                <form ref={form} onSubmit={sendEmail}> 
                        <Row>
                            <Input type="text" name="name" placeholder='Your Name' placeholderTextColor="black" value={name} onChange={handleName} required/>
                            <Gap />
                            <Input type="text" name="phone" placeholder='Your Phone' placeholderTextColor="black" value={phone} onChange={handlePhone} required/>
                            <Gap />
                            <Input type="email" name="email" placeholder='Your Email' placeholderTextColor="black" value={email} onChange={handleEmail} required/>
                            <Gap />
                            <Button type="submit" value="Send">Send Message</Button>
                        </Row>
                </form>
        </Section>
    )
}


const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: #083884;
  color: white;
  font-size: 1em;
  padding: 0.9em;
  width: 20%;
  border-radius: 50px;

  @media screen and (max-width: 930px){
        width: 90%;
        padding: 1.2em;
    }
`;

const Section = styled.section`
    padding: 94px 78px;
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 991px) {
        padding-right: 30px;
        padding-left: 30px;
    }

    @media screen and (max-width: 767px) {
        padding-right: 20px;
        padding-left: 20px;
    }

    @media screen and (max-width: 479px) {
        padding-right: 10px;
        padding-left: 10px;
    }
`;

const Input = styled.input`
	padding: 0.9em;
	color: black;
	background: white;
	border: black solid transparent;
	border-radius: 50px;
	width: 20%;
	margin-bottom: 0.5em;

    @media screen and (max-width: 930px){
        width: 90%;
        padding: 1.2em;
    }
`;

const Gap = styled.div`
    width: 30px;

    @media screen and (max-width: 930px){
        height: 20px;
        
    }

`;

const SmallHeading = styled.h2`
    font-size: 20px;
    color: #083884;
    margin-left: 40px;

    @media screen and (max-width: 1274px) {
        font-size: 18px;
        margin-left: 30px;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    padding: 30px;

    @media screen and (max-width: 930px){
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding: 30px;
        align-items: center;
        justify-content: center;
    }
`;

const TopHeading = styled.h1`
    font-size: 40px;
    color: #083884;
    margin-left: 40px;

    @media screen and (max-width: 1274px) {
        font-size: 36px;
        margin-left: 30px;
    }
`;

export default LeaveRequest
