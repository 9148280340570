import React, { useState } from 'react'
import './index.css';
import { FaAngleDoubleRight } from 'react-icons/fa';
import styled from 'styled-components';

import ServicesData from '../../ServicesData';

const Section = styled.section`
  width: 80%;
  margin: 5rem auto;
  max-width: 900px;
`;

const Points = styled.p`
  font-size: 18px;
  font-weight: 600;
`;

const IconImage = styled.img`
  width: 70px;
  height: 70px;
  margin-left: 220px;
`;

const Title = styled.h3`
    font-size: 22px;
`;

const Tabs = () => {

  const [value, setValue] = useState(0);
  const { company, icon, duties, title } = ServicesData[value]

  return (
 
    <Section id="services">
      <div className="title">
        <h2>Our Services</h2>
        <div className="underline"></div>
      </div>
      
      <div className="jobs-center">
        {/* btn container */}
       
        <div className="btn-container">
          {ServicesData.map((item, index) => {
            return (
              <button
                key={item.id}
                onClick={() => setValue(index)}
                className={`job-btn ${index === value && 'active-btn'}`}
              >
                {item.company}
              </button>
            )
          })}
          
        </div>
        {/* job info */}
        <article className="job-info">
          <Title>{title}</Title>
          <h4>{company}</h4>
          <IconImage src={icon} alt="icon"/>
          {duties.map((duty, index) => {
            return (
              <div key={index} className="job-desc">
                <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                <Points>{duty}</Points>
              </div>
            )
          })}
          
        </article>
        
      </div>
    </Section>

  )
}

export default Tabs