import React, { useState } from 'react'
import { Nav, NavContainer, NavLogo, NavLogoImage, BurgerIcon, NavMenu, NavItem, 
    NavLinks, NavButtonLink, NavButton, NavLinkss } from './NavbarElements';
import { FaBars } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import styled from 'styled-components';

import Phone from '../../images/phone.svg';
import Pin from '../../images/pin.svg';
    
const TopHeader = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    background-color: pink;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
    padding-left: 83px;

    @media screen and (max-width: 1144px) {
        height: 70px;
        justify-content: center;
        align-items: center;
        padding-left: 40px;
    }

    @media screen and (max-width: 744px) {
        height: 70px;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 644px) {
        height: 100px;
        justify-content: center;
    align-items: center;
    }
`;

const MainRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 10px;

    @media screen and (max-width: 744px) {
        padding-right: 10px;
        padding-left: 10px;
    }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
 
`;

const SubHead = styled.h1`
  font-size: 14px;
  color: black;
  font-family: 'Roboto', sans-serif;
  margin-top: 7px;

  @media screen and (max-width: 744px) {
        font-size: 10px;
        margin-top: 2px;
        margin-bottom: 2px;
    }


`;

const Icon = styled.img`
    width: 3.5%;
    height: 3.5%;
    margin-right: 17px;

    @media screen and (max-width: 744px) {
        width: 4%;
        height: 4%;
        margin-right: 17px;
    }

    @media screen and (max-width: 720px) {
        width: 10%;
        height: 10%;
        margin-right: 17px;
    }
`;

const Navbar = ({ toggle, navbarColor }) => {

    const [ navbar, setNavbar ] = useState(false);

    const changeBackground = () => {
        if(window.scrollY >= 75) {
            setNavbar(true)
        } else {
            setNavbar(false);
        }
    };

    const toggleHome = () => {
        scroll.scrollTop()
    }
    
    window.addEventListener('scroll', changeBackground);
    
    return (
        <>
            <TopHeader>
            <MainRow>
                    <Row>
                        <Icon src={Pin} alt="location"/>
                      
                        <SubHead>1st & 2nd floor, NCL complex, HT Rd, Kompally, Hyderabad, Telangana 500010</SubHead>
                        
                    </Row>
                    <Row>
                        <Icon src={Phone} alt="phone"/>
                        
                            <SubHead>040 4123 5555</SubHead>
                    
                    </Row>
                </MainRow>
            </TopHeader>
            <Nav navbarColor={navbar}>
                <NavContainer>
                    <NavLogo to='/' onClick={toggleHome}>
                        <NavLogoImage />
                    </NavLogo>
                    <BurgerIcon onClick={toggle}>
                        <FaBars />
                    </BurgerIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to='about'
                                smooth={true} duration={500} spy={true}
                                exact='true' offset={-80}
                            >About Us</NavLinks>
                        </NavItem>
                        
                        <NavItem>
                            <NavLinks to='services'
                                smooth={true} duration={500} spy={true}
                                exact='true' offset={-80}
                            >Services</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='why'
                                smooth={true} duration={500} spy={true}
                                exact='true' offset={-80}
                            >Why Us</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='request'
                                smooth={true} duration={500} spy={true}
                                exact='true' offset={-80}
                            >Contact Us</NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavButton>
                        <NavButtonLink href='https://www.konnectdiagnostics.com/' blank='true'>
                            Konnect Diagnostics
                        </NavButtonLink>
                    </NavButton>
            </NavContainer>
            </Nav> 
        </>
    )
}

export default Navbar
