import React from "react";
import styled from "styled-components";
import Vr from '../images/vr.svg';
import Family from '../images/family.svg';
import Lounge from "../images/loun.svg";
import Three from '../images/three.svg';

export default function VrSection() {
    return (
        <Section id='about'>
        <SmallHeading>We do things differently</SmallHeading>
        <TopHeading>For the first time in the city, we provide services like</TopHeading>
        <GridLayout>
            <ColumnContainer>
                    <Heading>Live scan room with virtual fetal museum / virtual fetal tour (VR)</Heading>
                    <Description>
                        Parents can watch stages of fetal development and various fetal videos on VR device.
                        <br/>
                        <br/>
                        Fetal face can be seen on VR.
                        <br/>
                        <br/>
                        For the first time in the city.
                    </Description>
            </ColumnContainer>
            <Image src={Vr} alt="Vr"/>
        </GridLayout>
        <GridLayout>
            <Image src={Family} alt="family"/>
            <ColumnContainer>
                    <Heading>Family Photo Booth</Heading>
                    <Description>
                        A family picture with 3D fetal face image added will be given by end of the day.
                    </Description>
            </ColumnContainer>
        </GridLayout> 
        <GridLayout>
            <Image src={Three} alt="threed"/>
            <ColumnContainer>
                    <Heading>3D Fetal Face Printing</Heading>
                    <Description>
                    3D printing of the fetal face in the form of a 
                    momento will be available on demand.
                    </Description>
            </ColumnContainer>
        </GridLayout>
        <GridLayout>
            <ColumnContainer>
                    <Heading>Luxury Lounge</Heading>
                    <Description>
                        Luxury lounge for waiting before the scan.
                    </Description>
            </ColumnContainer>
            <Image src={Lounge} alt="lounge"/>
        </GridLayout>
        </Section>
    );
}

const Section = styled.section`
    padding: 94px 78px;
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: -50px;
   
    @media screen and (max-width: 991px) {
        padding-right: 60px;
        padding-left: 60px;
    }

    @media screen and (max-width: 767px) {
        padding-right: 50px;
        padding-left: 50px;
    }

    @media screen and (max-width: 479px) {
        padding-right: 10px;
        padding-left: 10px;
    }
`;

const SmallHeading = styled.h2`
    font-size: 20px;
    color: white;
    padding-left: 4rem;

    @media screen and (max-width: 380px) {
        font-size: 16px;
        color: white;
        padding-left: 30px;
    }
`;

const TopHeading = styled.h1`
    font-size: 40px;
    color: white;
    padding-left: 4rem;

    @media screen and (max-width: 380px) {
        font-size: 30px;
        color: white;
        padding-left: 30px;
    }
`;

const GridLayout = styled.div`
    margin-right: auto;
    margin-left: auto;
    margin-top: 70px;
    width: 100%;
    align-items: stretch;
    grid-column-gap: 14px;
    grid-row-gap: 70px;
    display: grid;
    place-items: center;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 1160px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 22px;
        grid-column-gap: 12px;
    }

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 42px;
    }

    @media screen and (max-width: 907px) {
        grid-template-columns: 1fr ;
    }

    @media screen and (max-width: 620px) {
        grid-template-columns: 1fr;
    }
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 20px;
    margin-left: 70px;

    @media screen and (max-width: 400px) {
        margin-left: 2px;
    }
`;

const Heading = styled.h1`
    font-size: 40px;
    color: white;
    align-text: left;

    @media screen and (max-width: 400px) {
        font-size: 30px;
    }
`;

const Description = styled.h2`
    font-size: 20px;
    color: white;
`;

const Image = styled.img`
    width: 60%;
`;


