import ImageOne from '../src/images/fetal.jpg';
import ImageTwo from '../src/images/mo.jpg';
import ImageThree from '../src/images/two.jpg';
import ImageFour from '../src/images/four.jpg';

export const SliderData = [
    {
        title: 'WELCOME TO THE FETAL WORLD',
        path: '',
        image: ImageOne,
        alt: 'First',
        label: 'Contact us',
        description: 'We are committed to provide superior quality diagnostic care, provide antenatal interventional services and genetic counselling.'
    },
    {
        title: 'WELCOME TO THE FETAL WORLD',
        path: '',
        image: ImageTwo,
        alt: 'First',
        label: 'Contact us',
        description: 'We are committed to provide superior quality diagnostic care, provide antenatal interventional services and genetic counselling.'
    },
    {
        title: 'WELCOME TO THE FETAL WORLD',
        path: '',
        image: ImageThree,
        alt: 'First',
        label: 'Contact us',
        description: 'We are committed to provide superior quality diagnostic care, provide antenatal interventional services and genetic counselling.'
    },
    {
        title: 'WELCOME TO THE FETAL WORLD',
        path: '',
        image: ImageFour,
        alt: 'First',
        label: 'Contact us',
        description: 'We are committed to provide superior quality diagnostic care, provide antenatal interventional services and genetic counselling.'
    },
]
