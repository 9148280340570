import React from 'react';
import styled from 'styled-components';
import questions from '../questions';
import Question from './Question';

function FAQSection() {
    return (
        <Container>
        <FaqStyled>
                <TopLine />
                <TopHeading>Frequently <span>asked questions</span></TopHeading>
                <SmallHeading>
                    
                </SmallHeading>
                

                <div className="questions-con">
                    {
                        questions.map((q)=>{
                            return <Question key={q.id} {...q} />
                        })
                    }
                </div>  
        </FaqStyled>

        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: white;
`;

const ImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  align-items: left aligned;
  justify-content: 'flex-end';
`;

const SmallHeading = styled.h2`
    font-size: 20px;
    color: blue;
    
`;

const TopHeading = styled.h1`
    font-size: 40px;
    color: #083884;
    
`;

const TopLine = styled.p`
  color: #48dbfb;
  font-size: 24px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;

  @media screen and (max-width: 768px) {
      font-size: 10px;
  }
`;

const FaqStyled = styled.section`
    width: 80%;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        
    }

    .c-para{
        width: 60%;
        margin: 0 auto;
    }
    .questions-con{
        padding-top: 4rem;
    }
    .lines{
        position: absolute;
        left: 0;
        top: 300%;
        width: 100%;
        z-index: -1;
        img{
            width: 100%;
        }
    }

`;



export default FAQSection;
