import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
`;



const Map = () => {
    return (
        <Container>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3804.813982992047!2d78.47906241530666!3d17.51639098800142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb85e3f14297d1%3A0xa15d939956e6d6e7!2sKonnect%20Diagnostic%20Center%2C%20Kompally!5e0!3m2!1sen!2sin!4v1644396031511!5m2!1sen!2sin" width={1500} height={350}  allowfullscreen="" loading="lazy" />
        </Container>
    )
}

export default Map