import React from 'react';
import styled from 'styled-components';

import Phone from '../../images/phone-call.svg';
import Clock from '../../images/clock.svg';

import FooterImage from '../../images/footer.svg';

function Footer() {
  return (
    <FooterContainer>
      <InsideContainer>
        <FirstRow>
          <ContentWrapper>
                <RowOne>
                  <Name>Konnect Fetal World</Name>
                </RowOne>
                <Address>1st & 2nd floor, NCL complex, HT Rd, Kompally, Hyderabad, Telangana 500010.</Address>
                <Emergency>24-hour emergency assistance</Emergency>
                <GridLayout>
                    <Row>
                        <Icon src={Clock} alt="clock"/>
                        <Columm>
                            <Heading>Working hours</Heading>
                            <SubHead>9 am - 8 pm</SubHead>
                        </Columm>
                    </Row>
                    <Row>
                        <Icon src={Phone} alt="phone"/>
                        <Columm>
                            <Heading>Toll Free Number</Heading>
                            <SubHead>040 4123 5555</SubHead>
                        </Columm>
                    </Row>
                </GridLayout>
          </ContentWrapper>  
        <Image src={FooterImage} alt="Footer"/>
        </FirstRow>   
      </InsideContainer>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const InsideContainer = styled.div`
  width: 90%;
  height: 370px;
  background-color: #A6CFFE;

    @media screen and (max-width: 881px) {
      height: 400px;
    }

    @media screen and (max-width: 1060px) {
      height: 370px;
      padding-bottom: 30px;
    }

    @media screen and (max-width: 750px) {
      height: 470px;
      padding-bottom: 30px;
    }

    @media screen and (max-width: 470px) {
      height: 600px;
      padding-bottom: 30px;
    }
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const RowOne = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.h1`
  font-size: 30px;
  color: #5A446F;
 

  @media screen and (max-width: 1160px) {
    font-size: 28px;
  }

  @media screen and (max-width: 700px) {
    font-size: 20px;
  }

  @media screen and (max-width: 670px) {
    font-size: 20px;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }

  @media screen and (max-width: 450px) {
      font-size: 22px;
    }
`;

const Emergency = styled.h2`
    font-size: 30px;
    color: red;
    margin-top: 40px;

    @media screen and (max-width: 1160px) {
    font-size: 20px;
  }

    @media screen and (max-width: 700px) {
      font-size: 20px;
    }

    @media screen and (max-width: 670px) {
    font-size: 20px;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const Image = styled.img`
    width: 24.4%;
    margin-right: -50px;
    margin-top: -70px;

    @media screen and (max-width: 1300px) {
          margin-right: -20px;
          margin-top: 50px;
      }

      @media screen and (max-width: 1060px) {
          margin-right: -20px;
          margin-top: 100px;
      }

      @media screen and (max-width: 786px) {
        margin-right: -20px;
          margin-top: 160px;
      }

        @media screen and (max-width: 630px) {
          margin-right: -10px;
          margin-top: 290px;

        }

        @media screen and (max-width: 486px) {
          margin-right: -20px;
          margin-top: 250px;
          width: 35.4%;
        }

        @media screen and (max-width: 376px) {
            
        }
`;

const Address = styled.p`
  font-size: 18px;
  color: #5A446F;
  margin-top: 14px;

  @media screen and (max-width: 1160px) {
    font-size: 15px;
  }
`;


export const GridLayout = styled.div`
    margin-right: auto;
    margin-left: auto;
    margin-top: 70px;
    align-items: stretch;
    grid-column-gap: 14px;
    grid-row-gap: 10px;
    display: grid;
    place-items: center;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 1160px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 22px;
        grid-column-gap: 12px;
    }

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 42px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        
    }

    @media screen and (max-width: 620px) {
        grid-template-columns: 1fr;
    }
`;

const Columm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Heading = styled.h1`
  font-size: 18px;
  color: black;

  @media screen and (max-width: 470px) {
    font-size: 12px;
  }
`;

const SubHead = styled.h1`
  font-size: 17px;
  color: black;
  font-family: 'Roboto', sans-serif;

  @media screen and (max-width: 470px) {
    font-size: 15px;
  }
`;

const Icon = styled.img`
    width: 16%;
    height: 16%;
    margin-right: 17px;

    @media screen and (max-width: 744px) {
      width: 12%;
      height: 12%;
      margin-right: 17px;
    }

    @media screen and (max-width: 720px) {
      width: 12%;
      height: 12%;
      margin-right: 17px;
    }

    @media screen and (max-width: 630px) {
      width: 12%;
      height: 12%;
      margin-right: 17px;
    }
`;

export default Footer;
