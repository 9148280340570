const ServicesData = [
    {
    "id": "recAGJfiU4CeaV0HL",
    "order": 3,
    "title": "Fetal Imaging",
    "icon": require("./images/scren.png"),
    "duties": [
    "Early Pregnancy scan/dating- 3D/4D",
    "Early TIFFA/NT - 3D/4D",
    "TIFFA / Anomaly scan - 3D/4D",
    "Fetal Echocardiogram - 3D/4D",
    "Fetal Neuro Imaging",
    "Fetal Doppler",
    "Growth scans",
    "BPP",
    "Fetal MRI"
    ],
    "company": "Fetal Imaging"
    },
    {
    "id": "recIL6mJNfWObonls",
    "order": 2,
    "title": "Imaging In Fertility",
    "icon": require("./images/diag.png"),
    "duties": [
    "Follicular monitoring.",
    "Antral follicular study.",
    "Stromal volume in PCOS.",
    "Endometrical and perifollicular Doppler.",
    "3D imaging of uterus.",
    "Sono-salpingography.",
    "Penile Doppler.",
    "Scrotal imaging.",
    "Trans rectal USG for seminal vesicles prostate and ejaculatory ducts.",
    ],
    "company": "Imaging In Fertility"
    },
    {
    "id": "rec61x18GVY99hQq5",
    "order": 1,
    "title": "Fetal and Maternal interventions",
    "icon": require("./images/brain.png"),
    "duties": [
    "CVS.",
    "Amniocentesis.",
    "Fetal reduction.",
    "Maternal abdominal paracentesis/adnexal cyst aspiration.",
    "Cordocentesis. "
    ],
    "company": "Interventions"
    }
    ,
    {
    "id": "rec61x18GVY99hQq44",
    "order": 0,
    "title": "Genetics",
    "icon": require("./images/gene.png"),
    "duties": [
    "Genetic counselling",
    "Blood investigations"
    ],
    "company": "Genetics"
    }
    ];

export default ServicesData;